@charset "utf-8";

@use 'sass:color';
@use 'utilities/scale';
@use 'utilities/styles';

$scrollbar-size: scale.px-to-rem(5);
$scrollbar-border-radius: scale.px-to-rem(10);
$header-bar-content-height-xs: scale.px-to-rem(34);
$header-bar-content-height-xl: scale.px-to-rem(72);
$header-bar-padding-vertical: scale.px-to-rem(19);
$header-bar-scroll-height: scale.px-to-rem(56);
$header-bar-height-xs: $header-bar-content-height-xs + 2 * $header-bar-padding-vertical;
$header-bar-height-xl: $header-bar-content-height-xl + 2 * $header-bar-padding-vertical;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.logo-wrapper {
    flex-grow: 1;
    height: $header-bar-content-height-xs;
    text-align: center;
}

.header-navigation-wrapper {
    border-bottom: none;
    color: #fff;

    > .container-content {
        @include media-breakpoint-up(xxl) {
            position: relative;
        }
    }

    .icon-search {
        stroke: currentColor;

        @include media-breakpoint-up(xl) {
            height: scale.px-to-rem(18);
        }
    }

    .navigation-icon-newsletter {
        width: scale.px-to-rem(31);
        height: scale.px-to-rem(18);
        margin-top: 0;
        mask-size: 100% 100%;
    }
}

@include media-breakpoint-down(xxl) {
    .header-navigation-wrapper ul {
        width: auto;
    }
}

.header-navigation-container .logo {
    width: scale.px-to-rem(112);
    height: 100%;
    mask-position: center;
    mask-size: cover;

    @include media-breakpoint-up(xxl) {
        width: scale.px-to-rem(237);
        mask-size: 237px 100px;
    }
}

.logo-wrapper-fwf {
    flex-grow: 1;
    order: -1;
    margin-left: 0;
}

.logo-fwf {
    z-index: 1;
    display: inline-block;
    width: scale.px-to-rem(59);
    height: scale.px-to-rem(18);
    margin: auto 0;
    background-color: currentColor;
    mask-image: url('../logos/logo_fwf-scilog_menu.svg');
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
}

.logo-fwf-mobile {
    flex-grow: 1;

    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.page-header-none {
    margin-top: $header-bar-height-xs;

    @include media-breakpoint-up(xl) {
        margin-top: $header-bar-height-xl;
    }
}

.page-header-text {
    display: flex;
    flex-direction: column;
    margin-top: $header-bar-height-xs;

    @include media-breakpoint-up(xl) {
        margin-top: $header-bar-height-xl;
    }

    .page-header-title {
        header {
            @include media-breakpoint-up(md) {
                margin: scale.px-to-rem(50) 0 scale.px-to-rem(25) 0;
            }

            h1 {
                font-size: scale.px-to-rem(26);
                font-weight: 430;

                @include media-breakpoint-up(md) {
                    font-size: scale.fluid-size(26, 72, 576, 1642);
                }
            }

            .teaser {
                @include media-breakpoint-up(md) {
                    margin: scale.px-to-rem(15) 0 0 0;
                }
            }
        }
    }
}

.header-navigation-container {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        height: $header-bar-content-height-xl;
        padding-top: scale.px-to-rem(8);
        padding-bottom: scale.px-to-rem(8);
    }

    @include media-breakpoint-up(xxl) {
        height: auto;
    }
}

.header-mobile-menu-toggle {
    justify-content: space-around;

    @include media-breakpoint-up(xxl) {
        z-index: 1;
        display: inline-flex;
        margin-left: 0;
    }

    .close-button-line {
        min-height: scale.px-to-rem(2);
        background-color: currentColor;
    }

    .close-button-line:nth-of-type(2) {
        position: relative;
    }

    &:not([aria-expanded='true']) .close-button-line {
        margin: 0;
    }

    &[aria-expanded='true'] {
        .close-button-line:first-child {
            margin-bottom: scale.px-to-rem(-26);
        }

        .close-button-line:nth-of-type(2) {
            left: -100%;
            opacity: 0;
        }
    }
}

.header-menu {
    @include media-breakpoint-up(xxl) {
        flex-grow: 1;
    }

    @include media-breakpoint-between(md, xxl) {
        display: flex;

        .header-menu-upper {
            flex: 1;
            margin: auto 0;
        }
    }
}

.main-menu {
    @include media-breakpoint-up(xxl) {
        display: block;
    }
}

.main-menu-primary {
    li {
        > a {
            line-height: 1.2;
        }
    }
}

ul.header-desktop-dropdown {
    position: absolute;
    left: 0;
    display: none;
    translate: 0 -100%;

    @media (prefers-reduced-motion: no-preference) {
        transition: translate 0.5s ease-out;
    }

    @include media-breakpoint-up(xxl) {
        display: flex;
        flex-direction: column;
        width: max-content;
    }

    li {
        border-top: max(1px, 0.0625rem) solid #fff;
        background-color: var(--primary-color);

        &:has(a:hover) {
            color: var(--color-dark);
            background-color: var(--color-light);
        }
    }

    .header-desktop-dropdown-item-primary {
        border-top: max(1px, 0.0625rem) solid var(--color-dark);
        color: var(--color-dark);
        background-color: var(--color-light);

        &:has(a:hover) {
            color: var(--color-light);
            background-color: var(--color-dark);
        }
    }

    .header-desktop-dropdown-item-secondary {
        &:nth-of-type(3) {
            border-bottom: 1rem solid currentColor;
        }
    }

    a {
        display: block;
        padding: scale.px-to-rem(8) scale.px-to-rem(16) scale.px-to-rem(8) scale.px-to-rem(29);
        text-decoration: none;
    }
}

.main-menu-topics {
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(xxl) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: auto !important;
        border-top: 2px solid #fff;
        padding-top: scale.px-to-rem(8);
    }

    li {
        @include media-breakpoint-up(xxl) {
            flex-grow: 1;
            margin: 0;
            padding: scale.px-to-rem(8) scale.px-to-rem(10);
            text-align: center;

            &:first-child {
                margin-right: auto;
                text-align: left;
            }

            &:last-child {
                margin-left: auto;
                text-align: right;
            }
        }

        a {
            display: block;
            line-height: 1.2;
            text-align: center;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@include media-breakpoint-down(xxl) {
    .header-menu {
        width: 100%;
    }

    .header-menu-upper {
        display: flex;
    }

    .logo-wrapper {
        flex-grow: 1;
        text-align: center;
    }

    .header-mobile-menu-row-wrapper {
        max-height: calc(100vh - #{scale.px-to-rem(151)});
        max-height: calc(100dvh - #{scale.px-to-rem(151)}); // Height of the menu bar and secondary menu taking into account browser UI
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-color: currentColor transparent;

        &::-webkit-scrollbar {
            width: $scrollbar-size;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: $scrollbar-border-radius;
            background-color: currentColor;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #fff;
        }
    }

    .header-menu-left.header-desktop {
        margin-top: auto;
        margin-bottom: auto;
    }

    .header-search-button.header-mobile {
        width: scale.px-to-rem(21);
        height: scale.px-to-rem(21);
        margin: auto;
    }

    .main-menu-primary {
        display: block;
        max-height: none;

        li {
            padding-top: scale.px-to-rem(18);
            text-align: left;

            > a {
                margin-bottom: scale.px-to-rem(19);
            }
        }
    }

    .main-menu-secondary {
        position: absolute;
        right: 0;
        bottom: 0;
        flex-grow: 0;
        justify-content: space-between;
        width: auto;
        border: 0;

        li {
            padding: 0 scale.px-to-rem(20) 0 0;

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .main-menu-topics {
        border-top: 1rem solid currentColor;
        background-color: var(--color-light);

        li { // stylelint-disable-line no-descending-specificity
            border-bottom: styles.border-thin(1, var(--color-dark));
            padding-top: scale.px-to-rem(18);
            text-align: left;

            > a {
                margin-right: 29px;
                margin-bottom: scale.px-to-rem(19);
                margin-left: 29px;
                color: var(--color-dark);
            }
        }
    }
}

.header-menu-upper {
    @include media-breakpoint-up(xxl) {
        display: flex;
        gap: scale.px-to-rem(16);
        align-items: center;
        justify-content: center;
        height: $header-bar-content-height-xl;
    }

    .logo-wrapper {
        @include media-breakpoint-up(xxl) {
            position: absolute;
            display: block;
            flex-grow: 1;
            height: $header-bar-content-height-xl;
            padding-bottom: scale.px-to-rem(8);
        }
    }

    .logo {
        @include media-breakpoint-up(xxl) {
            display: block;
            margin: 0 auto;
        }
    }
}

.header-menu-left.header-desktop {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(xxl) {
        flex: 1;
        gap: inherit;
    }
}

.header-desktop-menu-toggle {
    display: none;
    margin-left: 0;

    @include media-breakpoint-up(xxl) {
        display: inline-flex;
    }
}

.header-search-wrapper.header-desktop {
    @include media-breakpoint-up(xxl) {
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        height: $header-bar-content-height-xs;
        border-left: styles.border-thin(2);
        padding-left: scale.px-to-rem(16);
    }
}

.header-menu-row-upper {
    ul {
        padding: 0;
        list-style: none;

        @include media-breakpoint-up(xxl) {
            display: flex;
            flex-grow: 1;
            align-items: center;
        }

        &.main-menu-primary {
            @include media-breakpoint-up(xxl) {
                display: none;
            }
        }

        &.main-menu-secondary {
            display: flex;
            flex-grow: 0;
            align-items: center;
            width: auto;
            padding: scale.px-to-rem(20) scale.px-to-rem(29) scale.px-to-rem(26);

            @include media-breakpoint-up(xxl) {
                display: none;
            }
        }

        li {
            margin: 0;

            @include media-breakpoint-up(xxl) {
                flex-grow: 1;
                padding: scale.px-to-rem(10);
                text-align: center;
            }

            a { // stylelint-disable-line no-descending-specificity
                display: block;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

body.main-menu-open {
    .flyout-mobile {
        padding-top: $header-bar-height-xs;
    }
}

.main-menu-secondary.header-desktop {
    @include media-breakpoint-up(xxl) {
        display: flex;
        flex: 1;
        gap: inherit;
        justify-content: flex-end;
        width: auto;
    }

    li {
        @include media-breakpoint-up(xxl) {
            display: inline-flex;
            justify-content: center;
            margin-left: 0;
            border-left: styles.border-thin(2);
            padding-left: scale.px-to-rem(16);
            font-size: var(--font-size-large);
        }

        &:first-of-type {
            @include media-breakpoint-up(xxl) {
                border-left: none;
                padding-left: 0;
            }
        }
    }

    .navigation-icon-item .button-icon:has(.navigation-icon-newsletter) {
        @include media-breakpoint-up(md) {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            padding: 0;
        }
    }
}

body.search-open {
    .header-search-overlay.header-desktop {
        top: -5px;
        padding: 0 scale.px-to-rem(82) 0 0;
    }

    .header-search-overlay .search-field {
        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    .header-search-wrapper.header-desktop {
        @include media-breakpoint-up(xxl) {
            border-color: transparent;
        }
    }

    .main-menu-secondary.header-desktop {
        @include media-breakpoint-up(xxl) {
            flex: 0;
        }
    }

    .main-menu-primary {
        display: flex;

        li {
            display: none;
        }
    }
}

@include media-breakpoint-up(xxl) {
    body.desktop-flyout-open {
        .header-navigation-wrapper {
            padding-top: 0;
        }

        .logo-wrapper, .header-navigation-underlay {
            z-index: 1;
        }

        .header-desktop-dropdown {
            top: 0;
            translate: 0 scale.px-to-rem(86);

            @media (prefers-reduced-motion: no-preference) {
                transition: translate 0.5s ease-in-out;
            }
        }

        .header-menu-middle-section {
            display: none;
        }
    }
}

body.body-scroll {
    .header-menu-upper {
        @include media-breakpoint-up(xxl) {
            height: auto;
        }
    }

    .header-menu-middle-section {
        @include media-breakpoint-up(xxl) {
            display: none;
        }
    }

    .logo {
        @include media-breakpoint-up(xxl) {
            background: url('../images/scilog-s.svg') no-repeat center;
            background-size: 32px 18px;
            mask-image: unset;
            mask-size: unset;
        }
    }

    .scilog-header-navigation-container {
        @include media-breakpoint-up(xxl) {
            height: $header-bar-scroll-height;
        }

        @media (prefers-reduced-motion: no-preference) {
            transition: height 0.5s ease-in-out;
        }
    }

    .logo-wrapper {
        padding-bottom: 0;

        @include media-breakpoint-up(xxl) {
            height: $header-bar-content-height-xs;
        }
    }

    .header-desktop-dropdown {
        display: none;
    }
}

@include media-breakpoint-up(xxl) {
    body.body-scroll.desktop-flyout-open {
        .header-desktop-dropdown {
            display: flex;
            translate: 0 scale.px-to-rem(54);
        }
    }
}

.header-bar {
    border-bottom: none;

    > .container-content {
        border-bottom: styles.border-thin();
    }
}

.header-bar-tag {
    background-color: var(--tertiary-color);
}

.icon-prev-circled {
    @include styles.icon-hover('prev_circled', '_hover', 'scilog');
}

.icon-next-circled {
    @include styles.icon-hover('next_circled', '_hover', 'scilog');
}

.side-column-social-media {
    .icon-facebook {
        @include styles.icon-hover('facebook_dark', '_hover', 'scilog');
    }

    .icon-linkedin {
        @include styles.icon-hover('linkedin_dark', '_hover', 'scilog');
    }

    .icon-twitter {
        @include styles.icon-hover('twitter_dark', '_hover', 'scilog');
    }
}

.social-media-block {
    .icon-facebook {
        @include styles.icon-hover('facebook_white', '_hover', 'scilog');
    }

    .icon-linkedin {
        @include styles.icon-hover('linkedin_white', '_hover', 'scilog');
    }

    .icon-twitter {
        @include styles.icon-hover('twitter_white', '_hover', 'scilog');
    }

    .icon-instagram {
        @include styles.icon-hover('instagram_white', '_hover', 'scilog');
    }

    .icon-youtube {
        @include styles.icon-hover('youtube_white', '_hover', 'scilog');
    }
}

.swiper-wrapper {
    .card-title {
        height: auto;
        margin: scale.px-to-rem(10) 0 scale.px-to-rem(30) 0;
    }
}

.news-list {
    scroll-margin-block-start: scale.px-to-rem(80);

    @include media-breakpoint-up(xl) {
        scroll-margin-block-start: scale.px-to-rem(120);
    }

    .card-title {
        height: auto;
        font-weight: 430;
        line-height: 1.2;
    }
}

.news-related {
    .card-title {
        margin-bottom: 0;
    }
}

.spotlight-content {
    p {
        font-family: var(--font-family);
        font-size: scale.px-to-rem(30);
        font-weight: 300;
    }
}

.quote-author {
    &::before {
        margin-right: 0;
        content: '';
    }
}

.content {
    figcaption {
        padding-left: 0;

        &::before {
            content: '';
        }
    }

    .teaser-ce-content {
        .swiper-slide:last-child::after {
            display: none;
        }
    }
}

.news-list,
.teaser-ce-list {
    .card {
        height: 100%;
    }

    .card-subtitle,
    .card-title {
        -webkit-line-clamp: initial;
    }
}

.card-with-image .card-tag { // stylelint-disable-line no-descending-specificity
    top: initial;
    right: 0;
    padding-right: 0;
    background-color: transparent;
    font-size: scale.px-to-rem(16);

    .news-list & {
        background-color: transparent;
    }
}

.teaser-ce-list {
    .card-with-image .card-tag {
        position: static;
        order: 2;
        margin-left: auto;
    }
}

.teaser-ce-content {
    @include media-breakpoint-up(md) {
        width: calc(33% + var(--bs-gutter-x) * 0.33);
    }

    &.teaser-ce-large,
    &.teaser-ce-small {
        @include media-breakpoint-up(md) {
            width: auto;
        }

        @include media-breakpoint-up(xxl) {
            width: auto;
        }
    }

    &.teaser-ce-large:not(:has(.swiper-initialized)) {
        .swiper-slide {
            max-width: scale.px-to-rem(438);
            margin-right: scale.px-to-rem(25);
        }
    }

    &.teaser-ce-small:not(:has(.swiper-initialized)) {
        .swiper-slide {
            max-width: scale.px-to-rem(323);
            margin-right: scale.px-to-rem(25);
        }
    }

    .card-title {
        height: auto;
        font-weight: 430;
        line-height: 1.2;
    }

    &.teaser-ce-small {
        .card-tag,
        .card-eyebrow {
            display: flex;
        }
    }

    .card-with-image .card-tag {
        .scheme-dark & {
            color: var(--primary-color-text);
        }
    }

    .swiper:not(.swiper-mobile-only) {
        .card-with-image .card-eyebrow .card-tag {
            background-color: transparent;
        }
    }

    &:has(.card-subtitle) .card-title {
        margin-bottom: scale.px-to-rem(14);
    }

    .card-subtitle,
    .card-title {
        -webkit-line-clamp: initial;
    }

    .box-grid-vertical::before {
        width: 0;
        border-left: 0;
        content: none;
    }

    .box-grid-vertical::after {
        width: 0;
        border-left: 0;
        content: none;
    }
}

.teaser-list-item {
    &:has(.card-subtitle) .card-title {
        margin-bottom: scale.px-to-rem(14);
    }

    @include media-breakpoint-up(md) {
        .box-grid-vertical::before, .box-grid-vertical::after {
            border-right: 0;
            border-left: 0;
            content: none;
        }

        &:nth-child(even) {
            .box-grid-vertical::before {
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 100%;
                border-right: styles.border-thin();
                content: '';
            }

            .box-grid-vertical::after {
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 100%;
                border-right: 0;
                content: '';
            }
        }

        .box-grid:nth-child(even) .box-grid-vertical::after,
        .box-grid:last-child .box-grid-vertical::after {
            display: none;
        }
    }
}

.segment.content:not(.container-content) {
    @include media-breakpoint-up(xl) {
        &.scheme-medium,
        &.scheme-dark {
            background-clip: content-box;
        }
    }
}

.segment-teaser-ce-with-swiper {
    .col-12 {
        overflow: hidden;
    }

    .container-content {
        position: relative;
    }
}

.segment-teaser-ce {
    .container-content {
        @include media-breakpoint-up(xl) {
            position: relative;
        }
    }
}

.button-cta, .button-next {
    &::after {
        display: none;
    }
}

.card-eyebrow-text {
    font-size: scale.px-to-rem(16);
}

.image-gallery-controls {
    .icon-shrink {
        background-image: url('../icons/scilog/shrink_dark.svg');
    }

    .icon-fullscreen {
        background-image: url('../icons/scilog/fullscreen_dark.svg');
    }

    .icon-download {
        background-image: url('../icons/scilog/download_dark.svg');
    }

    .icon-close {
        background-image: url('../icons/scilog/close_dark.svg');
    }
}

.news-content-elements-scilog, .news-related, .scilog-news-detail {
    @include media-breakpoint-up(md) {
        .side-column-container {
            padding-left: 0;

            .side-column {
                margin-left: 0;
            }
        }
    }

    .side-column {
        p {
            margin-bottom: scale.px-to-rem(20);
            font-family: var(--font-family);
        }

        h2,h3 {
            font-weight: bold;
        }
    }

    h2:not(.side-column h2):not(.news-related h2) {
        margin-top: scale.px-to-rem(30);
        margin-bottom: scale.px-to-rem(20);
        font-family: var(--running-text-font-family);
        font-size: scale.px-to-rem(20);
        font-weight: 500;

        &:first-child:first-of-type {
            margin-top: scale.px-to-rem(20);
        }

        @include media-breakpoint-up(md) {
            margin-top: scale.px-to-rem(40);
            font-size: scale.px-to-rem(28);

            &:first-child:first-of-type {
                margin-top: 0;
            }
        }
    }

    h3:not(.side-column h3) {
        margin-top: 0;
        margin-bottom: scale.px-to-rem(15);
        font-size: scale.px-to-rem(22);
        font-weight: 700;
    }

    // necessary for translated content elements
    a.l10n + h2:not(.side-column h2) {
        margin-top: scale.px-to-rem(20); // necessary for equal spacing on mobile layout

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    p:not(.quote, .side-column p, .card p, .teaser) {
        font-weight: 400;
        line-height: scale.px-to-rem(25);

        @include media-breakpoint-up(md) {
            line-height: scale.px-to-rem(32);
        }

        @include media-breakpoint-up(xl) {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    figure {
        margin-bottom: scale.px-to-rem(50);
    }

    .image-gallery {
        figcaption {
            max-width: 85% !important;
        }
    }

    .frame:not(.frame-type-quote, .frame-type-container-side-column-right, .frame-type-container-side-column-left) {
        margin-top: 0;
    }

    .frame-type-text {
        margin-bottom: scale.px-to-rem(50);
    }

    .frame-type-quote {
        margin-bottom: scale.px-to-rem(50);

        @include media-breakpoint-up(md) {
            margin-top: scale.px-to-rem(30);
            margin-bottom: scale.px-to-rem(70);
        }

        @include media-breakpoint-up(xl) {
            margin-top: scale.px-to-rem(50);
            margin-bottom: scale.px-to-rem(80);
        }
    }

    .frame-type-container-side-column-right, .frame-type-container-side-column-left {
        margin-top: 0;
        margin-bottom: scale.px-to-rem(30);

        @include media-breakpoint-up(md) {
            margin-top: scale.px-to-rem(20);
            margin-bottom: scale.px-to-rem(60);
        }

        .teaser {
            margin: 0 0 scale.px-to-rem(10) 0;

            @include media-breakpoint-up(md) {
                margin-bottom: scale.px-to-rem(50);
            }
        }
    }

    .frame-type-news_pi1 {
        margin-bottom: scale.px-to-rem(50);

        @include media-breakpoint-up(md) {
            margin-bottom: scale.px-to-rem(60);
        }
    }
}

footer {
    padding-bottom: 0;
}

.footer-claim {
    display: flex;
    flex-direction: row;
    gap: scale.px-to-rem(20);
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    border-bottom: none;
    color: #fff;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        padding-left: 0;
    }

    p { // stylelint-disable-line no-descending-specificity
        margin-bottom: 0;
    }
}

:is(p,div).teaser {
    font-size: scale.px-to-rem(18);

    @include media-breakpoint-up(sm) {
        font-size: scale.fluid-size(18, 31, 576, 1642);
    }
}

.page-header-text .page-header-title .page-header-invisible {
    margin: 0;
}

.page-header-small {
    .page-header-title {
        h1 {
            font-size: scale.px-to-rem(34);
            font-weight: 430;

            @include media-breakpoint-up(md) {
                font-size: scale.fluid-size(34, 72, 576, 1642);
            }
        }
    }
}

.page-header-big {
    .page-header-title {
        h1 {
            font-size: scale.px-to-rem(34);
            font-weight: 430;

            @include media-breakpoint-up(sm) {
                font-size: scale.fluid-size(34, 72, 576, 1642);
            }
        }
    }
}

.teaser-list-item, .teaser-ce-content {
    .card-text {
        a, p {
            font-family: var(--font-family);
            font-weight: 430;
        }
    }
}
